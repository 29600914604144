@import 'npm:bootstrap/dist/css/bootstrap.min.css';

.penguin {
	position: relative;
	margin: auto;
}
.penguin canvas:first-child {
	/* Make the penguin take the height of the canvas. */
	position: relative;
}
.penguin canvas {
	position: absolute;
	top: 0;
	left: 0;
}
.inventory {
	position: relative;
}
.itemList {
	height: 520px;
	overflow: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
